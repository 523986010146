import { FC } from 'react';
import { graphql } from 'gatsby';
import { HeadInner } from '../../components/Head';
import { Layout } from '../../components/layout';
import { ArticleHeading } from '../../components/organisms/ArticleHeading';
import { NewsTemplate } from '../../components/news/NewsTemplate';

type HeadProps = {
  data: GatsbyTypes.PressReleaseTemplateQueryQuery;
};

export const Head: FC<HeadProps> = (props) => {
  const pressRelease = props.data.contentfulPressRelease;
  if (!pressRelease) {
    return null;
  }

  return (
    <HeadInner
      path={`/press-release/${pressRelease.slug}`}
      title={pressRelease.title}
      description={pressRelease?.description?.description}
      imageUrl={
        pressRelease?.cover?.file?.url
          ? `https:${pressRelease?.cover?.file.url}`
          : undefined
      }
      width={pressRelease?.cover?.file?.details?.image?.width}
      height={pressRelease?.cover?.file?.details?.image?.height}
    />
  );
};

const PressReleaseTemplate: FC<{
  data: GatsbyTypes.PressReleaseTemplateQueryQuery;
}> = (props) => {
  const pressRelease = props.data.contentfulPressRelease;
  if (!pressRelease) return null;
  // 日付を切り出し
  const date = `${pressRelease.publishedAt?.slice(
    0,
    4
  )}.${pressRelease.publishedAt?.slice(5, 7)}.${pressRelease.publishedAt?.slice(
    8,
    10
  )}`;

  const links = [
    { name: 'お知らせ', path: '/news/' },
    {
      name: `${pressRelease.title}`,
      path: `/press-release/${pressRelease.slug}`,
    },
  ];

  return (
    <Layout links={links}>
      <ArticleHeading category='news' title={pressRelease.title ?? ''} />
      <NewsTemplate
        newsCategory='pressRelease'
        publishAt={`${date}`}
        content={pressRelease.content?.childMdx?.body!}
      />
    </Layout>
  );
};

export const query = graphql`
  query PressReleaseTemplateQuery($id: String) {
    contentfulPressRelease(id: { eq: $id }) {
      id
      slug
      title
      publishedAt
      description {
        description
      }
      content {
        content
        childMdx {
          body
        }
      }
      cover {
        gatsbyImageData
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
      metadata {
        tags {
          name
        }
      }
    }
  }
`;

export default PressReleaseTemplate;
